.Toastify__toast {
  min-height: 56px !important;
  border: 1px solid !important;
  border-radius: 8px !important;
  font-weight: 400 !important;
  background-color: #fff;
  padding-left: 16px;

  &-body {
    padding: 0 !important;
  }
  &-icon {
    margin-inline-end: 16px;
    width: 22px !important;
  }

  &--success {
    border-color: #010101 !important;
    color: #010101 !important;
    &-icon {
      svg {
        fill: rgba(127, 251, 80, 1);
      }
    }
  }
  &--info {
    border-color: #010101 !important;
    color: #010101 !important;
    &-icon {
      svg {
        fill: rgba(127, 251, 80, 1);
      }
    }
  }
  &--warning {
    border-color: #010101 !important;
    color: #010101 !important;
    &-icon {
      svg {
        fill: rgba(255, 236, 64, 1);
      }
    }
  }
  &--error {
    border-color: #010101 !important;
    color: #010101 !important;
    &-icon {
      svg {
        fill: rgba(251, 80, 80, 1);
      }
    }
  }
  &--default {
    border-color: #010101 !important;
    color: #010101 !important;
    &-icon {
      svg {
        fill: rgba(127, 251, 80, 1);
      }
    }
  }
}

.Toastify__close-button {
  align-self: center !important;
  width: 25px !important;
  height: 25px !important;
  opacity: 1;
}
