@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $format: "woff") {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    @if $format == "woff" {
      src: url("#{$file-path}.#{$format}") format($format);
    } @else {
      src: url("#{$file-path}.#{$format}") format("truetype");
    }
  }
}

@include font-face("Inter", "/assets/fonts/Inter-Light", 300, normal, "ttf");
@include font-face("Inter", "/assets/fonts/Inter-Regular", 400, normal, "ttf");
@include font-face("Inter", "/assets/fonts/Inter-Medium", 500, normal, "ttf");
@include font-face("Inter", "/assets/fonts/Inter-SemiBold", 600, normal, "ttf");
@include font-face("Inter", "/assets/fonts/Inter-Bold", 700, normal, "ttf");
@include font-face("Inter", "/assets/fonts/Inter-Black", 900, normal, "ttf");
@include font-face("Druk-Wide", "/assets/fonts/Druk-Wide-Bold", 500, normal, "ttf");
@include font-face("MonumentExtended", "/assets/fonts/MonumentExtended-Regular", 400, normal, "otf");
@include font-face("NotoSans", "/assets/fonts/NotoSans-Bold", 700, normal, "ttf");
